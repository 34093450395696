import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { MetaMaskProvider, Web3Provider } from 'modules';

import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { EnvConfig, initSentry, queryClient, queryPersistOptions } from 'core';
import { MainPage } from './pages/MainPage';
import { IntlProvider } from 'react-intl';

import './index.css';

initSentry(EnvConfig.SENTRY_DSN);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <IntlProvider messages={{}} locale="en" defaultLocale="en">
      <PersistQueryClientProvider client={queryClient} persistOptions={queryPersistOptions}>
        <Web3Provider>
          <MetaMaskProvider>
            <MainPage />
          </MetaMaskProvider>
        </Web3Provider>
      </PersistQueryClientProvider>
    </IntlProvider>
  </StrictMode>,
);
