import { useCallback, useEffect, useState } from 'react';
import { useSDK as useMetamask } from '@metamask/sdk-react';
import { useUserStore, KeypairTypeValue, WalletSource, AnyMangataError } from 'core';

export const useMetamaskAccount = () => {
  const { provider } = useMetamask();
  const { sdk: metamaskSdk } = useMetamask();
  const { setSelectedAccount, setSelectedWallet, selectedAccount } = useUserStore();
  const [error, setError] = useState<string | null>(null);

  const selectedAddress = selectedAccount?.address;
  const isWalletReady = !!selectedAddress;

  const connectWallet = useCallback(async () => {
    try {
      const connectRes = await metamaskSdk?.connect();
      const address = Array.isArray(connectRes) ? connectRes?.[0] : null;

      setError(null);
      if (address) {
        setSelectedWallet(WalletSource.Metamask);
        setSelectedAccount({
          address: address,
          type: KeypairTypeValue.MetamaskEthereum,
        });
      } else {
        setSelectedWallet(null);
      }
    } catch (e) {
      const error = e as AnyMangataError;

      if (error?.code === -32002) {
        setError('Connect wallet request is already pending. Open Metamask to proceed.');
        return;
      }

      setError(error?.message);
    }
  }, [metamaskSdk, setSelectedAccount, setSelectedWallet]);

  useEffect(() => {
    provider?.on('accountsChanged', () => {
      connectWallet();
    });

    return () => {
      provider?.removeAllListeners('accountsChanged');
    };
  }, [provider, connectWallet]);

  return { connectWallet, isWalletReady, error };
};
