import cls from 'classnames';
import { Button, Container, Text } from 'ui';
import { EnvConfig, useFaucetCatpchaMutation, useUserStore } from 'core';
import { GASP_TOKEN, useFaucetContract, useMetamaskAccount } from '../hooks';
import { ReactComponent as CheckIcon } from 'icons/check-fill.svg';
import { ReactComponent as WarningIcon } from 'icons/warning.svg';
import { Header } from '../layouts/Header';
import { AddTokenLayout } from '../layouts/AddTokenLayout';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useRef, useState } from 'react';
import { get } from 'lodash-es';
import { animated, useResize } from '@react-spring/web';

const DISABLED_BTN_STYLE =
  'px-10 py-2 bg-btn-secondary rounded-full text-secondary font-body-m items-center';

export function MainPage() {
  const { selectedAccount } = useUserStore();
  const { captchaMutation } = useFaucetCatpchaMutation();
  const selectedAddress = selectedAccount?.address;
  const containerRef = useRef<HTMLDivElement>(null);

  const { addGaspMutation } = useFaucetContract(undefined, selectedAddress);
  const { connectWallet, isWalletReady, error: walletError } = useMetamaskAccount();
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const { height } = useResize({
    container: containerRef,
  });

  const error = walletError || get(captchaMutation.error?.response?.data, 'message');
  const isClaimable = isWalletReady && !!captchaToken;
  const isSuccess = captchaMutation.isSuccess;

  const handleHcaptchaVerify = (token: string) => {
    setCaptchaToken(token);
  };

  const handleVerification = () => {
    if (!selectedAddress || !captchaToken) {
      return;
    }

    captchaMutation.mutate({ token: captchaToken, address: selectedAddress });
  };

  const handleDisconnect = () => {
    setCaptchaToken(null);
    captchaMutation.reset();
  };

  return (
    <Container
      className="prose relative h-[100vh] overflow-y-auto"
      justifyContent="space-between"
      alignItems="center"
      column
    >
      <Header onConnect={connectWallet} onDisconnect={handleDisconnect} />
      <Container className="h-full items-center justify-center mb-10 min-h-[500px]" column>
        <animated.div style={{ height, overflow: 'hidden' }}>
          <Container className="bg-widget w-[480px] rounded-lg" ref={containerRef} column>
            <Container column className="p-8 ">
              <Container column className="mr-10">
                <Text type="display" className="text-[38px] leading-[1.2]">
                  Welcome&nbsp;to&nbsp;Gasp Testnet Faucet
                </Text>
                {!isSuccess && (
                  <Text type="title-2" className="mt-4 mb-2" weight="bold">
                    Connect your Metamask
                  </Text>
                )}
              </Container>
              {!isSuccess && (
                <Text type="body-l" color="secondary">
                  Connect your Ethereum wallet to claim GASPV2 testnet tokens
                </Text>
              )}
              {!isSuccess &&
                (selectedAccount ? (
                  <Container className="mt-4 px-4 bg-[#EFFAF5] rounded-lg h-[48px] w-full justify-center items-center">
                    <Container>
                      <CheckIcon className="w-6 h-6 mr-2 fill-success" />
                      <Text type="body-m" className="text-success">
                        Connected to Ethereum
                      </Text>
                    </Container>
                  </Container>
                ) : (
                  <Button onClick={connectWallet} className="mt-5">
                    Connect wallet
                  </Button>
                ))}

              {selectedAccount && (
                <Container fullWidth column className="mt-6">
                  {!isSuccess && EnvConfig.CAPTCHA_SITEKEY && (
                    <HCaptcha sitekey={EnvConfig.CAPTCHA_SITEKEY} onVerify={handleHcaptchaVerify} />
                  )}
                  {error && (
                    <Text
                      type="body-m"
                      className="inline-flex mt-4 items-center max-w-[400px]"
                      color="alert"
                    >
                      <WarningIcon className="w-6 h-6 mr-2 fill-icon-alert min-w-[24px] self-start" />
                      {error}
                    </Text>
                  )}
                  <Button
                    size="m"
                    className={cls(
                      'mr-2 px-10 py-2',
                      !isSuccess && 'mt-6',
                      !isClaimable && DISABLED_BTN_STYLE,
                      (captchaMutation.isLoading || isSuccess) &&
                        'bg-btn-primary/[.1] text-highlight border-1 border-highlight pointer-events-none',
                      'normal-case',
                    )}
                    isDisabled={!isClaimable || captchaMutation.isLoading}
                    onClick={handleVerification}
                    fullWidth
                  >
                    {isSuccess && <CheckIcon className="w-6 h-6 mr-2 fill-icon-highlight" />}
                    {captchaMutation.isLoading
                      ? 'Claiming...'
                      : isSuccess
                      ? `${GASP_TOKEN.name} Claimed`
                      : `Claim Testnet tokens`}
                  </Button>
                  {isSuccess && <AddTokenLayout addGaspMutation={addGaspMutation} />}
                </Container>
              )}
            </Container>
          </Container>
        </animated.div>

        {isSuccess && (
          <Container className="bg-highlight rounded-lg px-5  w-[480px] mt-2 pb-5 pt-1" column>
            <Text type="body-m" className="mt-4" weight="bold" color="inverted">
              Don’t forget to deposit tokens to Gasp!
            </Text>
            <Text type="body-m" color="inverted" className="opacity-70 font-[400]">
              After receiving your GASPV2 tokens, you need to deposit them to your Gasp account to
              be able to use them. Use the deposit feature directly in the Gasp app.
            </Text>
          </Container>
        )}
      </Container>
    </Container>
  );
}
